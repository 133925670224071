iframe#webpack-dev-server-client-overlay{display:none!important}
@import "~antd/dist/antd.css";

body {
  color: #37383c;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-variant-ligatures: none;
  font-weight: 400;
  letter-spacing: -0.1px;
  line-height: 1.4;
  margin: 0;
  padding: 0;
}
#root {
  height: 100vh;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
a,
a:hover {
  text-decoration: none;
}
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
  background: linear-gradient(to right, #e04e61, #e04e61)!important;
}
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: linear-gradient(to right, #e04e61, #e04e61)!important;
}
.ant-spin {
  color: #e04e61!important;
}
.ant-spin-dot-item {
  background-color: #e04e61!important;
}
.ant-menu-submenu-title:hover {
  color: #e04e61!important;
}
.ant-menu-item-selected  {
  color: #e04e61!important;
}
.ant-menu-submenu-selected {
  color: #e04e61!important;
}
.ant-menu-item::after {
  border-right: 3px solid #e04e61!important;
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, 
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #e04e61!important;
}
a {
  color: #e04e61;
  transition: color 0.2s linear;
  cursor: pointer;
}
.ant-btn.ant-btn-default {
  margin-left: 5px;
  background-color: #bcf5f7;
  color: #236fcc;
  border: none;
  border-radius: 8px;
  height: 40px;
  transition: 0.3s;
}
.ant-btn .ant-btn-default:focus {
  background: #ff4d4f;
  border-color: #f3475b;
}
.ant-btn.ant-btn-default:hover {
  background-color: #3b82f6;
  box-shadow: 0 0 0 5px #3b83f65f;
  color: #fff;
}
.uploader {
  width: 128px;
  height: 128px;
}

.uploader > div {
  width: 128px !important;
  height: 128px !important;
}

.custom-pagination > p {
  margin-top: 1px !important;
}

.table-wrapper {
  height: 1000px;
  border: 1px solid var(--borderColor);
  overflow: auto;
}
  
thead.sticky {
  position: sticky;
  top: 0;
  z-index: 10;
  background: var(--stickyBackground);
  box-shadow: 0 0 6px rgba(0,0,0,0.25);
}

.sticky > tr {
  border-top: 1px solid #f0f0f0;
}